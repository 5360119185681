import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { notificationBannerProps } from './proptypes';

const Banner = ({ data, getI18nLabel, getI18nLink, handleClose }) => (
  <Fragment>
    <div className="phillips__nav__notification__block" />
    <div className="phillips__nav__notification">
      <div className="container">
        <div className="row">
          <p>{getI18nLabel({ value: data.notificationMessage })}</p>
          {data.notificationActionUrl || data.notificationAction ? (
            <p>
              <a target="_blank" href={data.notificationActionUrl}>{getI18nLabel({ value: data.notificationAction })}</a>
            </p>
):null}
        </div>
        <button className="close" type="button" onClick={handleClose}>
          <img src={`${GLOBAL_CDN_URL}images/icons/close-white.svg`} width="30px" height="30px" alt="close button" />
        </button>
      </div>
    </div>
  </Fragment>
);

Banner.propTypes = {
  data: notificationBannerProps.isRequired,
  getI18nLabel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default Banner;