/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { stickyPropType } from '../PropTypes/proptypes';
import StickyItem from './StickyItem';

class StickyBanner extends Component {
  constructor(props) {
    super(props);

    this.refStickyContainer = React.createRef();
    this.pos = {
      x1: 10,
      y1: 66,
      x2: 0,
      y2: 0
    };

    this.el = null;

    this.state = {
      currentIndex: 0,
      length: this.props.data?.length || 0,
      sortedData: [],
      hasSingle: true
    };
  }

  componentDidMount () {
    this.makeDraggableElement("sticky_container");
    const sortedData = this.props.data.sort((a, b) => (a.TimeState > b.TimeState ? 1 : a.TimeState < b.TimeState ? -1 : 0));
    let prevState = null;
    for(const item of sortedData) {
      if(prevState === item.TimeState) {
        item.duplicated = true;
        if(item.TimeState === 2) {
          this.setState({ hasSingle: false });
        }
      }
      prevState = item.TimeState;
    }

    this.setState({ sortedData });
  }

  onPrev = () => {
    if (this.state.currentIndex > 0) {
      this.setState(prevState => ({ currentIndex: prevState.currentIndex - 1 }));
    }
    else {
      this.setState(prevState => ({ currentIndex: prevState.length - 1 }));
    }
  };

  onNext = () => {
    if (this.state.currentIndex < (this.state.length - 1)) {
      this.setState(prevState => ({ currentIndex: prevState.currentIndex + 1 }));
    }
    else {
      this.setState({ currentIndex: 0 });
    }
  };

  makeDraggableElement = (id) => {
    this.el = document.getElementById(id);
    this.el.onmousedown = this.dragMouseDown;
    this.el.ontouchstart = this.dragMouseDown;
  };

  dragMouseDown = (e) => {
    // e.preventDefault();
    // get the mouse cursor position at startup:
    this.pos.x2 = e.touches ? e.touches[0].clientX : e.clientX;
    this.pos.y2 = e.touches ? e.touches[0].clientY : e.clientY;
    document.onmouseup = this.closeDragElement;
    document.ontouchend = this.closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = this.elementDrag;
    document.ontouchmove = this.elementDrag;
  };

  elementDrag = (e) => {
    if (this.el === null) {
      return;
    }

    e.preventDefault();
    // calculate the new cursor position:
    this.pos.x1 = this.pos.x2 - (e.touches ? e.touches[0].clientX : e.clientX);
    this.pos.y1 = this.pos.y2 - (e.touches ? e.touches[0].clientY : e.clientY);
    this.pos.x2 = e.touches ? e.touches[0].clientX : e.clientX;
    this.pos.y2 = e.touches ? e.touches[0].clientY : e.clientY;
    // set the element's new position:
    this.el.style.top = `${this.el.offsetTop - this.pos.y1}px`;
    this.el.style.left = `${this.el.offsetLeft - this.pos.x1}px`;
  };

  closeDragElement = () => {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.ontouchend = null;
    document.onmousemove = null;
    document.ontouchmove = null;
  }

  render() {
    const { language, data, getI18nLabel, handleClose, notificationEnabled } = this.props;
    return (
      <div className={classNames("sticky-banner__wrapper", { 'sticky-banner__wrapper__notification': notificationEnabled,"hong-kong": language === 'zh' })}>
        <div
          id="sticky_container"
          className={`sticky-banner__container sticky-banner__container${data.length === 1 ? "--single" : ""}`}
          ref={this.refStickyContainer}
        >
          {data.length > 1
            ? (
              <ul className="sticky-banner__container__navigator">
                {this.state.sortedData.map((item, index) => (
                  <li
                    className={classNames("sticky-banner__container__navigator__indicator", { "activated": index === this.state.currentIndex })}
                    key={`sticky-indicator-${item.SaleNumber}${item.Session ? `${item.Session.CSessionTime}-${item.Session.LotsRange}` : ''}`}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        this.resetTimer();
                        this.setState({ currentIndex: index });
                      }}
                      title={item.Title}
                    />
                  </li>
                ))}
              </ul>
            )
            : null}
          <ul className="sticky-banner__content" style={{ left: `${-100 * this.state.currentIndex}%` }}>
            <button
              type="button"
              className="sticky-banner__container__close-button"
              onClick={handleClose}
            >
              &times;
            </button>
            {this.state.sortedData.map((item, index) => (
              <li
                className={`sticky-banner__item sticky-banner__item${index === 0 ? "--first-child": ""}`}
                key={`sticky-item-${item.SaleNumber}${item.Session ? `${item.Session.CSessionTime}-${item.Session.LotsRange}` : ''}`}
              >
                <StickyItem
                  {...item}
                  index={index}
                  language={language}
                  getI18nLabel={getI18nLabel}
                  isFirst={index === 0}
                  hasSingle={this.state.hasSingle}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

StickyBanner.defaultProps = {
  language: 'en',
  data: []
};

StickyBanner.propTypes = {
  language: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape(stickyPropType)),
  getI18nLabel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default StickyBanner;
